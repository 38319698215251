@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: "burtons";
  src: url("./resources/fonts/norwester.otf");
}

@font-face {
  font-family: "helveticaNeue-Bold";
  src: url("./resources/fonts/HelveticaNeue-Bold.otf");
}

@font-face {
  font-family: "helveticaNeue-Medium";
  src: url("./resources/fonts/HelveticaNeue-Medium.otf");
}

@font-face {
  font-family: "neueHelveticaBQ-Bold";
  src: url("./resources/fonts/NeueHelveticaBQ-Bold.otf");
}


/* LOGIN PAGE FOR DASHBOARD */
/* LOGIN PAGE FOR DASHBOARD */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.login-form {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  width: 300px;
}

.login-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login-form button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 1rem;
}

.login-form button:hover {
  background-color: #0056b3;
}

.login-form h1 {
  font-size: 1.5rem; /* Adjust this value to your liking */
  margin-bottom: 20px; /* Optional: Add some space below the header */
}